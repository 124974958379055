import React from "react"
import { Link } from "gatsby"

import useDeviceDetect from "../../../../service/useDeviceDetect"

const Col3Section = (props) => {

    const { content } = props

    const { isMobile } = useDeviceDetect()

    const defaultImageSrc = isMobile === true ? "https://via.placeholder.com/328x206" : "https://via.placeholder.com/350x220"

    const cards = (content && content?.relationships && content?.relationships?.components) ? content.relationships.components : {}

    const renderLink = (data) => {
        const cta = data && data.title ? data.title : ''
        let link = data && data.uri ? data.uri : 'javascript:void(0)'
        if (link) {
            if (link.includes('internal:')) {
                const arr = link.split('internal:')
                if (arr && arr.length) {
                    link = arr[1]
                }
            }
        }
        return (
            <Link to={link} className="btn btn-primary">{cta}</Link>
        )
    }

    return (
        <div className="container">
            <div className="row">
                {cards && cards.length &&
                    cards.map((el, index) => {
                        const imageSrc = el?.relationships?.image?.uri?.url;
                        const altText = "Hinduja Hospital"
                        return (
                            <div key={index} className={`${cards.length === 4 ? "col-lg-3" : "col-lg-4"} col-md-6`}>
                                <div className="card">
                                    {
                                        imageSrc 
                                        ? <img src={imageSrc} alt={altText} />
                                        : <img src={defaultImageSrc} alt={altText} />                                                                 
                                    }
                                    <div className="card-body">
                                        <h3>{el.title ? el.title : ''}</h3>
                                        {
                                            el.description && el.description.processed &&
                                            <div dangerouslySetInnerHTML={{ __html: el.description.processed }}></div>
                                        }
                                    </div>
                                    <div className="card-link">
                                        {   el.link &&
                                            renderLink(el.link)
                                        }
                                        {/* <a href="#" className="btn
                                        btn-primary">Buy now</a> */}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Col3Section
