import React from "react"
import { Link } from "gatsby"

import QuotedText from "../../quotedText"

import useDeviceDetect from "../../../../service/useDeviceDetect"

const FounderSection = (props) => {
    
    const { content } = props

    const { isMobile } = useDeviceDetect()

    const defaultImageSrc = isMobile === true ? "https://via.placeholder.com/90x135" : "https://via.placeholder.com/241x362"

    const components = (content && content?.relationships && content?.relationships?.components) ? content.relationships.components : {}
    let altText = ""
   
    let imageSrc = null
    if (components.length > 0) {
        altText = components[0]?.image?.alt || "Hinduja Hospital"
        imageSrc = components[0]?.relationships?.image?.uri?.url;
    }

    return (
        <div className="container">
            <div className="row">
            <div className="col-md-5">
                <figure className="about_img">
                    {   imageSrc ?
                        <img
                            src={imageSrc}
                            alt={altText}
                        />
                        :
                        <img   
                           src={defaultImageSrc}
                           alt={altText} />                       
                    }
                </figure>
            </div>
            <div className="col-md-7">
                <div className="about_content">
                    {
                        (components && components[0]) &&
                        <>
                            <h2>{components[0].title ? components[0].title : ''}</h2>
                            {
                                components[0]?.description?.processed &&
                                <div dangerouslySetInnerHTML={{__html : components[0].description.processed}}>
                                </div>
                            }
                        </>
                    }
                    {
                        (components && components[1]) &&
                        <QuotedText
                            content={components[1].quote}
                        />
                    }
                    <p>
                        <Link className="btn btn-primary" to="/about-founder">Know more</Link>
                    </p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default FounderSection
