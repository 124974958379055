import React, { useState, useEffect } from "react"
import { graphql, navigate } from "gatsby"

import Modal from "react-bootstrap/Modal"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import BtnLink from "../components/addon/Link"
import HomeBanner from "../components/pageSections/homePage/homeBanner"
import FounderSection from "../components/pageSections/homePage/aboutFounder"
import Col3Section from "../components/pageSections/homePage/col3Section"
import Col2Section from "../components/pageSections/homePage/col2Section"
import HomeSlider from "../components/addon/homeSlider"

import API from "../api/doctors"
import useScript from '../service/useScript';
import APIS from "../api"

const IndexPage = (props) => {
    const { data } = props
    let [components, setComponents] = useState([])
    const [subCatDisabled, setSubCatDisabled] = useState(false)
    let pageInfo = {}
    let metaTags = []
    let canonicalUrl = {}
    let forPatientsCardLinks = []
    let schema = []
    if (data && data.allNodeTemplatePages && data.allNodeTemplatePages.edges) {
        pageInfo = data.allNodeTemplatePages.edges[0]
        metaTags = pageInfo?.node?.metatags ? pageInfo.node.metatags : []
        canonicalUrl = pageInfo?.node?.metatags_on_page || {}
        schema = pageInfo?.node?.relationships?.field_seo_schema ? pageInfo.node.relationships?.field_seo_schema : []
    }
    
    if (data && data?.allMenuItems?.edges) {
        forPatientsCardLinks = data.allMenuItems.edges
        if (forPatientsCardLinks && forPatientsCardLinks.length) {
            forPatientsCardLinks = forPatientsCardLinks.map(el => {
                return {
                    link: {
                        uri: el?.node?.url ? el.node.url : '',
                        title: el?.node?.title ? el.node.title : ''
                    }
                }
            })
        }
    }
    useScript("/assets/js/custom_select.js");
    let [specialities, setSpecialities] = useState([])
    let [specPlaceholder, setSpecPlaceholder] = useState("Search by Speciality")
    let [subSpecPlaceholder, setSubSpecPlaceholder] = useState("Sub Category")
    let [selectedSpeciality, setSelectedSpeciality] = useState(null)
    let [subSpecialities, setSubSpecialities] = useState([])
    let [selectedSubSpeciality, setSelectedSubSpeciality] = useState(null)
    let [allSpecialities, setAllSpecialities] = useState([])
    const [activeSlideShow, setActiveSlideShow] = useState("h_virtual_tour")

    const [showModal, setShowModal] = useState(false)
    const showHomeModel = () => {
        setShowModal(false)
    }
    const closeModal = () => {
        setShowModal(false)
    } 

    useEffect(() => {
        getSpecialities()
        const components = pageInfo?.node?.relationships?.components ? pageInfo.node.relationships.components : []
        setComponents(components)
        showHomeModel();
    }, [])
    const getSpecialities = () => {
        API.getSpecialities()
            .then(response => {
                if (response && response.length > 0) {
                    setAllSpecialities(response)
                    let arr = response.map(el => {
                        return {
                            name: el?.name,
                            id: el?.id,
                            path: el?.path
                        }
                    })
                    arr.sort(function(a, b){
                        if(a.name < b.name) { return -1; }
                        if(a.name > b.name) { return 1; }
                        return 0;
                    })

                    setSpecialities(arr)
                    if (typeof window.initSelect !== 'undefined') window.initSelect();
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    const onSubSpecialitySelect = (event) => {
        const selectedId = event.target.value
        if (selectedId && selectedId !== "default") {
            const selectedObj = allSpecialities.find(item => {
                return item.children.find(el => el.id === selectedId)
            })
            const subSpec = selectedObj.children.find(el => el?.id === selectedId && el)
            let subSpecName = subSpec?.name;
            setSubSpecPlaceholder(subSpecName);
            setSelectedSubSpeciality(subSpecName);
        }
    }
    const onSpecialitySelect = (event) => {
        setSelectedSubSpeciality(null)
        const selectedId = event.target.value
        fillSubSpcialities(selectedId)
    }
    const fillSubSpcialities = (selectedId) => {

        if (selectedId) {
            const selectedObj = allSpecialities.find(el => el.id === selectedId)
            setSpecPlaceholder(selectedObj?.name)
            setSelectedSpeciality(selectedObj?.name)
            if (selectedObj.hasOwnProperty("children")) {
                let arr = selectedObj?.children?.map(el => {
                    return {
                        name: el?.name,
                        id: el?.id,
                        path: el?.path
                    }
                })
                arr.sort(function(a, b){
                    if(a.name < b.name) { return -1; }
                    if(a.name > b.name) { return 1; }
                    return 0;
                })
                
                setSubSpecialities(arr)
                setSubCatDisabled(arr.length > 0 ? false : true);
            }

        }
    }
    useEffect(() => {
        if (typeof window.initSelect !== 'undefined') window.initSelect();
    }, [subSpecialities])

    const searchDoctors = () => {
        if(selectedSubSpeciality){
            const sub = subSpecialities.find(s => s.name === selectedSubSpeciality)
            if(sub && sub.path){
                navigate(sub.path)
            }
        }else if(selectedSpeciality){
            const sub = specialities.find(s => s.name === selectedSpeciality)
            if(sub && sub.path){
                navigate(sub.path)
            }
        }else{
            let url = '/find-a-doctor'
            if (selectedSpeciality !== null) {
                url = url + '?category=' + encodeURIComponent(selectedSpeciality)

                if (selectedSubSpeciality !== null) {
                    url = url + '&sub_category=' + encodeURIComponent(selectedSubSpeciality)
                }
                navigate(url)
            }
        }
    }

    let [searchList, setSearchList] = useState([])
    let [loading, setLoading] = useState(false)
    const onSearchTextChange = (data) => {
        const text = data
        if (text) {
            search(text)
        } else if (text.trim() === '') {
            clearData()
        }
    }
    const search = (searchText) => {
        setLoading(true)
        const json = {
            'type': 'all',
            'keyword': searchText
        }
        APIS.siteSearch(json)
            .then(response => {
                if (response && response.length > 0) {
                    const arr = response.map((el, index) => {
                        return {
                            ...el,
                            id: 'id-' + index
                        }
                    })
                    setSearchList(arr)
                }
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setSearchList([])
                setLoading(false)
            })
    }
    const onSearchTextSelect = (data) => {
        if (data && data.length > 0) {
            const obj = data[0]
            if (obj.path) {
                navigate(obj.path)
            }
        }
    }
    const clearData = () => {
    }
    const setActiveSlide = (tabName) => {
        setActiveSlideShow(tabName)
    }

    //100 rs off image
    const hrc = components.find(c => c.name === 'banner_book_appointment');
    let hundredRupeeImage, hrc_cta;
    if (hrc && hrc?.relationships && hrc?.relationships?.components && hrc?.relationships?.components.length > 0) {
        hundredRupeeImage = hrc?.relationships?.components[0]?.relationships?.bannerImage?.uri?.url 
        hrc_cta = hrc?.relationships?.components[0]?.bannerLink;
    }

    const banner_bottom = components.find(c => c.name === 'Linkset');
    return (
        <Layout>
            <Meta
                tags={{metaTags,canonicalUrl}}
                files={
                    {
                        js: [],
                        css: ["/assets/css/homepage.css"]
                    }
                }
                meta={
                    {
                        name: 'google-site-verification',
                        content: 'gaGNv1Z-lI6nMrBrRmmrRHxRXkdyxj_BQXq3EebsXuE',
                        page:'Homepage'
                    }
                }
            />
            <Schema schema={schema} />
            <main>
                <section className="hero_banner overflow-hidden">
                    {
                        (components && components[0]) &&
                        <HomeBanner
                            content={components[0]}
                            bottomSectionContent={banner_bottom}
                        />
                    }
                </section>
                <section className="section_bg plus_icon icon_big section-pt">
                    <div className="container">
                        {/* 
                <AsyncTypeahead
                    delay={1000}
                    placeholder="Search Name/Specialities/Health Condition"
                    labelKey="title"
                    onSearch={onSearchTextChange}
                    onChange={onSearchTextSelect}
                    isLoading={loading}
                    options={searchList}
                    id="globalSiteSearch"
                    searchText="Searching..."
                    useCache={false}
                    />
                */}
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="rounded_card bg_white for_patients">
                                    <h2 className="section-heading btm_bar">For Patients</h2>
                                    <p className="content_with_heading">Which of these health care services can we help you with?
                                    </p>
                                    <ul className="listing">
                                        {
                                            (forPatientsCardLinks && forPatientsCardLinks.length > 0) &&
                                            forPatientsCardLinks.map((el, index) => {
                                                return (
                                                    <li key={index}>
                                                        <BtnLink
                                                            data={el}
                                                            classes=""
                                                        />
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    {(hrc && hundredRupeeImage) &&
                                        <div className="btm_banner">
                                            <BtnLink data={{ link: hrc_cta }}>
                                                <img src={hundredRupeeImage} alt="hundredrupeeImage" />
                                            </BtnLink>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="rounded_card search_speciality">
                                    <h1 className="section-heading btm_bar">
                                        Know about our <br />Specialities, Doctors & Clinics
                                        <svg className="plus_icon"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            width="146.639" height="146.828" viewBox="0
                                    0 146.639 146.828"
                                        >
                                            <defs>
                                                <linearGradient id="linear-gradient"
                                                    x1="0.5" y1="1" x2="0.5"
                                                    gradientUnits="objectBoundingBox">
                                                    <stop offset="0"
                                                        stopColor="#e3f0ff" />
                                                    <stop offset="0.768"
                                                        stopColor="#1b57a2" />
                                                    <stop offset="1"
                                                        stopColor="#044597" />
                                                </linearGradient>
                                            </defs>
                                            <path id="Path_1873"
                                                data-name="Path 1873"
                                                d="M754.042,322.984c5.24,0,10.481-.064,15.72.017a19.49,19.49,0,0,1,16.5,8.649,18.95,18.95,0,0,1,3.234,14.244,19.414,19.414,0,0,1-9.7,14.2,18.494,18.494,0,0,1-9.534,2.663c-10.822.053-21.644.025-32.468.028-.29,0-.58.013-.869,0-.444-.018-.617.2-.6.624.012.263,0,.526,0,.79,0,10.586.02,21.172-.009,31.757a19.94,19.94,0,0,1-7.212,15.742,19.078,19.078,0,0,1-8.833,4.271,19.424,19.424,0,0,1-19.054-6.813,19.916,19.916,0,0,1-4.689-13.079c-.027-10.744-.01-21.488-.012-32.232,0-1.061,0-1.064-1.095-1.064q-15.919,0-31.837,0a19.694,19.694,0,0,1-15.676-6.971,19.889,19.889,0,0,1,.686-26.638,18.489,18.489,0,0,1,10.695-5.756,33.807,33.807,0,0,1,5.1-.421c10.347-.038,20.7-.019,31.044-.019,1.087,0,1.089,0,1.089-1.073,0-11.007-.107-22.016.05-33.022a19.636,19.636,0,0,1,13.04-18.117,19.866,19.866,0,0,1,24.136,8.9,19.513,19.513,0,0,1,2.569,9.726q.035,16.195.012,32.391c0,1.193,0,1.194,1.2,1.2Z"
                                                transform="translate(789.757
                                        416.387) rotate(180)"
                                                opacity="0.242"
                                                fill="url(#linear-gradient)"
                                            />
                                        </svg>
                                    </h1>
                                    <p className="content_with_heading">
                                        Find the right doctor for your needs
                                        <svg className="plus_icon"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="61.797"
                                            height="61.889" viewBox="0 0
                                    61.797 61.889">
                                            <path id="Path_1941"
                                                data-name="Path 1941"
                                                d="M689.106,291.708c2.172,0,4.345-.026,6.517.007a8.08,8.08,0,0,1,6.84,3.586,7.856,7.856,0,0,1,1.341,5.905,8.049,8.049,0,0,1-4.02,5.889,7.666,7.666,0,0,1-3.953,1.1c-4.487.022-8.973.01-13.461.012-.12,0-.24.005-.36,0-.184-.007-.256.082-.247.259.005.109,0,.218,0,.328,0,4.389.008,8.777,0,13.166a8.267,8.267,0,0,1-2.99,6.527,7.91,7.91,0,0,1-3.662,1.771,8.053,8.053,0,0,1-7.9-2.825,8.258,8.258,0,0,1-1.944-5.422c-.011-4.454,0-8.909-.005-13.363,0-.44,0-.441-.454-.441H651.6a8.165,8.165,0,0,1-6.5-2.89,8.246,8.246,0,0,1,.284-11.044,7.665,7.665,0,0,1,4.434-2.386,14.015,14.015,0,0,1,2.113-.175c4.29-.016,8.58-.008,12.87-.008.451,0,.452,0,.452-.445,0-4.563-.044-9.127.021-13.69a8.141,8.141,0,0,1,5.406-7.511,8.236,8.236,0,0,1,10.007,3.689,8.088,8.088,0,0,1,1.065,4.032q.015,6.714.005,13.429c0,.495,0,.495.5.5Z"
                                                transform="translate(704.415
                                        330.931) rotate(180)"
                                                fill="none"
                                                stroke="#f6924d"
                                                strokeMiterlimit="10"
                                                strokeWidth="1" />
                                        </svg>
                                    </p>
                                    <div className="form_container">
                                        <div className="form-group select-dd">
                                            <select onChange={onSpecialitySelect} name="" id=""
                                                className="form-control custom-select" placeholder={specPlaceholder}>
                                                {
                                                    (specialities && specialities.length > 0) &&
                                                    specialities.map((el, index) => {
                                                        return (
                                                            <option
                                                                key={index}
                                                                value={el.id}
                                                            >{el.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className={`form-group select-dd ${subCatDisabled ? 'select-disabled' : ''}`}>
                                            <select onChange={onSubSpecialitySelect} name="" id="" className="form-control custom-select" placeholder="Sub Category">
                                                {
                                                    (subSpecialities && subSpecialities.length > 0) &&
                                                    subSpecialities.map((el, index) => {
                                                        return (
                                                            <option
                                                                key={index}
                                                                value={el.id}
                                                            >{el.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <button
                                                className="btn btn-primary"
                                                onClick={searchDoctors}
                                            >Search</button>
                                        </div>
                                    </div>
                                    <figure className="btm_img">
                                        <img className="lazyload" src="/assets/images/search_speciality_bg_img.png" data-src="/assets/images/search_speciality_bg_img.png" alt="" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_bg plus_icon about_section">
                    {
                        (components && components[3]) &&
                        <FounderSection
                            content={components[3]}
                        />
                    }
                </section>
                <section className="three_cards_section">
                    {
                        (components && components[4]) &&
                        <Col3Section
                            content={components[4]}
                        />
                    }
                </section>
                <section className="section_bg section-py">
                    {
                        (components && components[5]) &&
                        <Col2Section
                            content={components[5]}
                        />
                    }
                </section>
                <section className="three_cards_section">
                    {
                        (components && components[6]) &&
                        <Col3Section
                            content={components[6]}
                        />
                    }
                </section>
                <section className="section_bg section-py hp_gallery overflow-hidden">
                    <div className="container">
                        <h3 className="section-heading text-center">Hospital Tour</h3>
                        {/* <ul className="nav nav-tabs gallery-tabs justify-content-center" role="tablist">
                            <li role="presentation" className="nav-item">
                                <a onClick={() => setActiveSlide('h_virtual_tour')} className="nav-link active" data-toggle="tab" href="#h_virtual_tour" role="tab" aria-controls="h_virtual_tour" aria-selected="true">Hospital Virtual Tour</a>
                            </li>
                            <li role="presentation" className="nav-item">
                                <a onClick={() => setActiveSlide('h_gallery')} className="nav-link" data-toggle="tab" href="#h_gallery" role="tab" aria-controls="h_gallery" aria-selected="false">Hospital Gallery</a>
                            </li>
                        </ul> */}
                    </div>
                    <div className="tab-content mt-3">
                        <div className="tab-pane active" id="h_virtual_tour" role="tabpanel" aria-labelledby="h_virtual_tour">
                            {(components && components[7]?.relationships?.components) &&
                                <HomeSlider
                                    data={components[7].relationships.components}
                                />
                            }
                            <p className="text-center"><a href="javascript:;" className="btn btn-primary mt-4">View all</a></p>
                        </div>
                        {/* <div className="tab-pane" id="h_gallery" role="tabpanel" aria-labelledby="h_gallery">
                            {
                                (components && components[8]?.relationships?.components) &&
                                <HomeSlider
                                    data={components[8].relationships.components}
                                />
                            }
                            <p className="text-center"><a href="javascript:;" className="btn btn-primary mt-4">View all</a></p>
                        </div> */}
                    </div>
                </section>
                <Modal
                    show={showModal}
                    animation={false}
                    centered
                    size="lg"
                    dialogClassName="common_modal homepageModel mBodyPop"
                >
                    <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">X</span>
                    </button>
                    <div class="modal-header">
                        <h2>Kind Attention:</h2>
                    </div>
                    <Modal.Body>
                        <p><strong>Tariff will be revised w.e.f. 01/04/2024.</strong></p>                        
                    </Modal.Body>
                </Modal>
            </main>
        </Layout>
    )
}
export const query = graphql`
query homePage {
    allMenuItems(filter: {menu_name: {eq: "for-patients"}}) {
    edges {
    node {
    title
    description
    url
    }
    }
    }
    allNodeTemplatePages(filter: {path: {alias: {eq: "/home"}}}) {
    edges {
    node {
    id
    title
    path {
    alias
    }
    relationships{
        field_seo_schema{
            field_seo_title
            field_seo_schema_text_area
        }
    }
    metatags: metatag_normalized {
    attributes {
    content
    name
    property
    }
    }
    metatags_on_page: field_meta_tags{
        canonical_url
      }
    relationships {
    components: field_component_type {
    __typename
    ...ParagraphSection
    ...ParagraphImageComponentIndex
    }
    }
    }
    }
    }
    }
    
    
    
    fragment ParagraphSection on paragraph__section {
    id
    name: field_name
    relationships {
    components: field_components {
    __typename
    ...ParagraphQuote
    ...ParagraphLinkset
    ...ParagraphImageTitleDescLink
    ...ParagraphBanner
    ...ParagraphImageWithTitleAndDescription
    ...ParagraphSlideshowItem
    }
    }
    }
    
    
    
    fragment ParagraphImageComponentIndex on paragraph__image_component {
    id
    image: field_image {
    alt
    }
    relationships {
    image: field_image {
    id
    uri {
    value
    url
    }
    }
    }
    }
    
    
    
    fragment ParagraphQuote on paragraph__quote {
    id
    quote: field_quotation
    }
    
    
    
    fragment ParagraphBanner on paragraph__banner {
    id
    title: field_title
    subtitle: field_subtitle
    bannerImage: field_banner_image {
    alt
    }
    mobilebannerImage: field_mobile_banner {
    alt
    }
    bannerLink: field_banner_link {
    uri
    title
    }
    relationships {
    bannerImage: field_banner_image {
    id
    uri {
    value
    url
    }
    }
    mobilebannerImage: field_mobile_banner {
    id
    uri {
    value
    url
    }
    }
    }
    }
    
    
    
    fragment ParagraphLinkset on paragraph__link_set {
    id
    title: field_title
    subtitle: field_subtitle
    field_link {
    title
    uri
    }
    icon: field_icon {
    alt
    }
    relationships {
    icon: field_icon {
    id
    uri {
    value
    url
    }
    }
    }
    }
    
    
    
    fragment ParagraphImageTitleDescLink on paragraph__image_title_desc_link {
    id
    title: field_title
    title1: field_title_1
    description: field_description {
    processed
    }
    link: field_link {
    uri
    title
    }
    image: field_image {
    alt
    }
    relationships {
    image: field_image {
    id
    uri {
    value
    url
    }
    }
    }
    }
    
    
    
    fragment ParagraphImageWithTitleAndDescription on paragraph__image_with_title_and_description {
    id
    title: field_title
    description: field_content {
    processed
    }
    image: field_image {
    alt
    }
    alignment: field_alignment
    relationships {
    image: field_image {
    id
    uri {
    value
    url
    }
    }
    }
    }
    
    
    
    fragment ParagraphSlideshowItem on paragraph__slideshow_item {
    id
    image: field_image {
    alt
    }
    link: field_link {
    uri
    title
    }
    relationships {
    image: field_image {
    id
    uri {
    value
    url
    }
    }
    image_mobile: field_image {
    id
    uri {
    value
    url
    }
    }
    }
    }
`
export default IndexPage