import React from "react" 

import { navigate } from "gatsby"

const BottomSection = (props) => {

    const { content } = props

    const links = content && content?.relationships?.components ? content.relationships.components : []

    const getIconSrc = (data) => {
        let src = ''
        if (data && data?.uri?.url) {
            src = data.uri?.url
            return src
        }
        return src
    }

    const getAltText = (data) => {
        let alt = ''
        if (data && data?.icon?.alt) {
            alt = data.icon.alt
            return alt
        }
        return alt
    }

    const onLinkClick = (data) => {
        let link = data && data?.field_link?.uri ? data.field_link.uri : '/'
        if (link) {
            if(link.includes('http')){
                window.open(link, '_blank').focus();
            }else{
                if (link.includes('internal:')) {
                    const arr = link.split('internal:')
                    if (arr && arr.length) {
                        link = arr[1]
                    }
                }
                navigate(link)
            }
        }        
    }

    return (
        <div className="banner_btm_band">
            <div className="container">
                <div className="white_bg">
                    <div className="row">
                        {
                            links.map((el, index) => {
                                return (
                                    <div key={index} className="col-md-3 col-6">
                                        <a className="d-block link_box"
                                        href="javascript:;" onClick={() => onLinkClick(el)}>
                                            {
                                                el.relationships && el.relationships.icon &&
                                                <img className="lazyload"
                                                    src={getIconSrc(el.relationships.icon)}
                                                    alt={getAltText(el)}
                                                />
                                            }
                                        <h5 className="box_heading">{el.title ? el.title : ''}</h5>
                                        <p className="box_content">{el.subtitle ? el.subtitle : ''}</p>
                                        <span className="arrow_link is-right arrow-bar"></span>
                                        </a>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BottomSection
